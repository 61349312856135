import axios from 'axios'
import { filter, map, get, toLower } from 'lodash'
import config from '../../config/config'
function combineOutlet(outlet) {
  const toLowerCode = toLower(outlet.code)
  const combineOutlet = {
    code: outlet.code,
    name: outlet.name,
    _id: outlet._id,
    tabNamespaceString: `/${toLowerCode}`,
    receiptPrinterNamespaceString: `/${toLowerCode}/receipt-printer`,
    orderPrinterNamespaceString: `/${toLowerCode}/savor-printer`,
    labelPrinterNamespaceString: `/${toLowerCode}/label-printer`,
    mobileAppNamespaceString: `/${toLowerCode}/mobile-app`,
    products: [],
    tabNamespace: null,
    orderPrinterNamespace: null,
    labelPrinterNamespace: null,
    mobileAppNamespace: null,
    receiptPrinterNamespace: null,
    numLabelPrinters: 0,
    numOrderPrinters: 0,
    numMobileApps: 0,
    numReceiptPrinters: 0
  }
  return combineOutlet
}
async function getSavorOutlets() {
  const { data: outlets } = await axios.get(`${config.workServer.uri}/outlets`)
  const filteredOutlets = filter(outlets, outlet => {
    const outletCode = get(outlet, 'code', 'Chưa rõ')
    return /^(S|K)\d+/.test(outletCode) || outletCode === 'B2'
  })
  const combineAllOutlet = map(filteredOutlets, outlet => combineOutlet(outlet))
  return combineAllOutlet
}
let outlets
export const getOutlets = async function() {
  if (!outlets) {
    outlets = await getSavorOutlets()
  }
  return outlets
}

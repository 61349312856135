import React, { Component } from 'react'
import { Menu } from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
class TopMenu extends Component {
  state = {}

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render() {
    const { loggedIn, currentShift } = this.props
    const pathName = this.props.location.pathname
    const isPrinting = pathName.match(/print-label|print-sale-entry|print-order$/)

    return (
      <Menu className="no-print" inverted color="green">
        {loggedIn && !isPrinting ? (
          <Menu.Menu position="left">
            <Menu.Item
              as={Link}
              to="/home"
              name="editorials"
              onClick={this.handleItemClick}
              active={pathName === '/home'}
            >
              Savor POS
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="/print-order"
              name="editorials"
              onClick={this.handleItemClick}
              active={pathName === '/print-order'}
            >
              Print Order
            </Menu.Item>
          </Menu.Menu>
        ) : (
          ''
        )}

        {!loggedIn && (
          <Menu.Menu position="right">
            <Menu.Item as={Link} name="login" to="/login" />
          </Menu.Menu>
        )}
        {loggedIn && (
          <Menu.Menu position="right">
            <Menu.Item>{currentShift.shiftRecordId.replace('_undefined', '')}</Menu.Item>
          </Menu.Menu>
        )}
      </Menu>
    )
  }
}

export default withRouter(TopMenu)

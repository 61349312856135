import React from 'react'

import moment from 'moment'
import store from 'store'

import {
  subcribeToNewOrder,
  unsubcribeToPrint,
  initSocket,
  removeSocket
} from '../../../../shared/apis/savor-server.api'

import workServer from '../../../../shared/apis/work-server.api'

import './PrintOrder.scss'

import { Container } from 'semantic-ui-react'
import {
  flatMap,
  range,
  get,
  map,
  orderBy,
  groupBy,
  sumBy,
  reduce,
  cloneDeep,
  find,
  reject
} from 'lodash'
import uid from 'uid-safe'
import { FormattedNumber } from 'react-intl'
import { getPrintTemplate } from './print-template'
import { toast } from 'react-toastify'

const REMOVE_MODIFIERS_DESCRTIIONS = ['Hộp giấy giấy bạc']

const getOrderFromGroupName = groupName => {
  if (groupName === 'veggie') return 1
  if (groupName === 'bread-addon') return 2
  if (groupName === 'extra') return 3
  if (groupName === 'combo-drink') return 4
  if (groupName === 'drink-addon') return 5

  return 999
}

const getSauceFromItems = itemSku => {
  if (['V0207', 'V0208'].includes(itemSku)) {
    return { sku: 'V0339', description: 'Sốt nấm', quantity: 1 }
  }
  if (['V0747'].includes(itemSku)) {
    return { sku: 'V0757', description: 'Sốt bò nướng', quantity: 1 }
  }
  if (['V0077', 'V0665', 'V0096', 'V1068'].includes(itemSku)) {
    return { sku: 'V0322', description: 'Sốt teriyaki', quantity: 1 }
  }
  if (['V0336', 'V0335'].includes(itemSku)) {
    return { sku: 'V0063', description: 'Sốt thịt', quantity: 1 }
  }
  return null
}

const getNameFromGroupName = groupName => {
  let name = ''
  if (groupName === 'bread-addon') name = 'Sốt'
  else if (groupName === 'veggie') name = 'Rau'
  else if (groupName === 'combo-drink') name = 'Nước 10k'
  else if (groupName === 'ice') name = 'Đá'
  else if (groupName === 'sugar') name = 'Đường'
  else if (groupName === 'drink-addon') name = 'Nước'
  else name = 'Addon'
  return name
}

function ItemModifier({ modifier, hasComma }) {
  return (
    <span className="modifier" key={modifier.sku}>
      {modifier.description}
      {modifier.quantity > 1 ? <strong> x{modifier.quantity}</strong> : ''}
      {!!hasComma && <span>, </span>}
    </span>
  )
}

function NewItemModifiers({ modifiers }) {
  return modifiers && modifiers.length > 0 ? (
    <div style={{ marginTop: 5 }}>
      <strong>Thêm: </strong>
      {modifiers.map((modifier, index) => (
        <ItemModifier
          index={index}
          hasComma={index < modifiers.length - 1}
          modifier={modifier}
          key={modifier.sku}
        />
      ))}
    </div>
  ) : (
    ''
  )
}

function StickyRiceNewItemModifiers({ modifiers }) {
  if (!get(modifiers, 'length')) {
    return ''
  }
  return (
    <div style={{ marginTop: '5px' }}>
      <div style={{ width: '100%' }}>
        <strong>Thêm: </strong>
        <ul
          style={{
            margin: 0,
            columns: 2,
            WebkitColumns: 2,
            MozColumns: 2,
            paddingLeft: '15px',
            fontSize: '11px'
          }}
        >
          {modifiers.map((modifier, index) => (
            <ExtraItemModifier index={index} modifier={modifier} key={modifier.sku} />
          ))}
        </ul>
      </div>
    </div>
  )
}
function ExtraItemModifier({ modifier }) {
  return (
    <li className="modifier" key={modifier.sku}>
      {modifier.description}
      {modifier.quantity > 1 ? <strong> x{modifier.quantity}</strong> : ''}
    </li>
  )
}

function BreadModifierDetails({ itemSku, newModifiers, normalModifiers }) {
  return (
    <div className="modifiers">
      <div className="normal-modifiers" style={{ fontStyle: 'italic' }}>
        {map(
          orderBy(
            map(groupBy(normalModifiers, 'modifierSetCode'), (modifiers, modifierSetCode) => {
              return {
                code: modifierSetCode,
                modifiers
              }
            }),
            [d => getOrderFromGroupName(d.modifierSetCode)],
            ['asc']
          ),
          (modifierSet, index) => {
            const modifierSetCode = modifierSet.code
            const modifiers = modifierSet.modifiers
            if (modifierSetCode === 'bread-addon') {
              const sauceItem = getSauceFromItems(itemSku)
              if (sauceItem) {
                modifiers.unshift(sauceItem)
              }
            }
            const name = getNameFromGroupName(modifierSetCode)

            return (
              <span key={index}>
                <strong>{name}: </strong>

                {modifiers.map((modifier, index) => (
                  <ItemModifier
                    key={modifier.sku}
                    modifier={modifier}
                    hasComma={index < modifiers.length - 1}
                  />
                ))}
                <br />
              </span>
            )
          }
        )}
      </div>
      <NewItemModifiers modifiers={newModifiers} />
    </div>
  )
}

function NormalModifierDetails({ normalModifiers, newModifiers }) {
  console.log('NormalModifierDetails', normalModifiers, newModifiers)
  return (
    <div className="modifiers">
      <div style={{ fontStyle: 'italic' }}>
        {normalModifiers.map((modifier, index) => (
          <ItemModifier
            index={index}
            hasComma={index < normalModifiers.length - 1}
            modifier={modifier}
            key={modifier.sku}
          />
        ))}
      </div>
      <NewItemModifiers modifiers={newModifiers} />
    </div>
  )
}

function StickyRiceModifierDetails({ newModifiers, normalModifiers }) {
  const extraNormalModifiers = normalModifiers.filter(modifier => {
    return modifier.quantity >= 2
  })
  const cloneExtraNormalModifiers = cloneDeep(extraNormalModifiers)
  cloneExtraNormalModifiers.forEach(extraNormalModifier => {
    extraNormalModifier.quantity -= 1
  })
  cloneExtraNormalModifiers.forEach(extraNormalModifier => {
    newModifiers.push(extraNormalModifier)
  })

  const smallStickyRice = find(normalModifiers, { description: 'Xôi nhỏ' })
  if (!smallStickyRice) {
    newModifiers = reject(newModifiers, { description: 'Xôi to' })
  }
  return (
    <div className="modifiers">
      <StickyRiceNewItemModifiers modifiers={newModifiers} />
    </div>
  )
}

function ItemModifiers({ item, defaultModifierProductMap }) {
  console.log('ItemModifiers', item)
  const matchProduct = defaultModifierProductMap[item.sku] || {}
  const defaultModifierSkus = matchProduct.defaultModifierSkus || []
  let normalModifiers =
    defaultModifierSkus && defaultModifierSkus.length > 0
      ? item.modifiers.filter(modifier => {
          return defaultModifierSkus.includes(modifier.sku)
        })
      : item.modifiers
  normalModifiers = normalModifiers.filter(modifier => {
    return !REMOVE_MODIFIERS_DESCRTIIONS.includes(modifier.description)
  })

  const newModifiers =
    defaultModifierSkus && defaultModifierSkus.length > 0
      ? item.modifiers.filter(modifier => {
          return !defaultModifierSkus.includes(modifier.sku)
        })
      : []
  if (['V1300', 'V1301'].includes(item.sku)) {
    return <NormalModifierDetails normalModifiers={item.modifiers} newModifiers={newModifiers} />
  }
  switch (item.category) {
    case 'milk-tea':
    case 'juice':
    case 'cake':
      return <NormalModifierDetails normalModifiers={normalModifiers} newModifiers={newModifiers} />
    case 'sticky-rice':
      return (
        <StickyRiceModifierDetails normalModifiers={normalModifiers} newModifiers={newModifiers} />
      )
    case 'bread':
      return (
        <BreadModifierDetails
          itemSku={item.sku}
          normalModifiers={normalModifiers}
          newModifiers={newModifiers}
        />
      )
    default:
      return ''
  }
}

function ItemDescription({ description, item }) {
  let outputDescription = description
  outputDescription = outputDescription.replace('- Size M', '(M)')
  outputDescription = outputDescription.replace('- Size L', '(L)')
  outputDescription = outputDescription.replace('- Cỡ to', '')
  outputDescription = outputDescription.replace('- Cỡ nhỏ', '')
  outputDescription = outputDescription.replace(/Trân Châu Trắng/i, '\nTrân Châu Trắng')
  return (
    <div className="item-description">
      <strong>{outputDescription}</strong>
    </div>
  )
}

function ItemHeader({
  referrer,
  saleEntryId,
  index,
  numItems,
  shift,
  orderNumber,
  ordered,
  customerName,
  cellphone
}) {
  let localReferrer = referrer.replace('SAVOR_', '').replace('_FOOD', '')

  return (
    <div className="header">
      {localReferrer} <strong>{saleEntryId.slice(0, 3).replace('_', '')}</strong>_
      <strong>
        {shift}_{orderNumber}
      </strong>
      {'  '}
      {index + 1}/{numItems} {ordered}
      {(customerName || cellphone) && (
        <div>
          KH: {customerName} - {cellphone}
        </div>
      )}
    </div>
  )
}

export default class PrintOrder extends React.Component {
  state = {
    items: [],
    numItems: 0,
    printType: 'order',
    ordered: '',
    shift: '',
    defaultModifierProductMap: null
  }

  reorderItems = items => {
    return orderBy(items, ['order'], ['asc'])
  }

  printOrder = (err, data) => {
    if (!this.state.defaultModifierProductMap) {
      toast.error('Chưa load xong dữ liệu topping')
      return
    }
    console.log('printOrder.data', data)
    const allPrintingItems = reduce(
      data.computedItems,
      (result, item) => {
        if (item.isCombo) {
          return result
        }
        let pushMainItem = true

        if (item.bundledProducts && item.bundledProducts.length) {
          item.bundledProducts.forEach(bundledProduct => {
            if (
              ['V0877', 'V0878', 'V0879', 'V0880', 'V1009', 'V1010', 'V1011', 'V1012'].includes(
                bundledProduct.sku
              )
            ) {
              pushMainItem = false
              result.push({
                sku: bundledProduct.sku,
                description: bundledProduct.description,
                soldPrice: item,
                lineTotal: 0,
                noLoyalty: item.noLoyalty,
                quantity: item.quantity,
                modifiers: [],
                category: 'sandwich',
                saleProgram: item.saleProgram
              })
            }
          })
        }
        if (item.modifiers && item.modifiers.length) {
          item.modifiers.forEach(modifier => {
            if (
              ['combo-drink', 'drink-addon'].includes(modifier.modifierSetCode) &&
              !['V0327', 'V0001', 'V0716'].includes(modifier.sku)
            ) {
              result.push({
                sku: modifier.sku,
                description: modifier.description,
                soldPrice: 0,
                quantity: item.quantity,
                noLoyalty: item.noLoyalty,
                modifiers: [],
                category: modifier.modifierSetCode,
                saleProgram: item.saleProgram,
                note: `Bán kèm ${item.description}`
              })
            }
          })
        }
        if (pushMainItem) {
          result.push(item)
        }
        return result
      },
      []
    )
    console.log('printOrder.allPrintingItems', allPrintingItems)

    const numItems = sumBy(allPrintingItems, item => {
      return item.quantity
    })
    const printCats = [
      'bread',
      'cake',
      'tokbokki',
      'sandwich',
      'juice',
      'sticky-rice',
      'noodle',
      'milk-tea',
      'cake',
      'fried'
    ]
    const filteredItems = reject(
      allPrintingItems,
      item =>
        (!printCats.includes(item.category) &&
          !item.description.match(/cà phê/i) &&
          !['V1004', 'V0602', 'V0424']) ||
        item.sku === 'V2857'
    )

    const ordered = moment(data.ordered).format('DD-MM HH:mm')
    const flattenItems = flatMap(filteredItems, item =>
      range(item.quantity).map(() => ({
        ...item,
        modifiers: item.modifiers.sort((a, b) => {
          return a.modifierSetCode > b.modifierSetCode
        }),
        id: uid.sync(6)
      }))
    )

    this.setState({
      saleEntryId: data.orderId,
      outlet: data.outlet,
      orderNumber: data.orderNumber,
      customerName: get(data, 'customer.fullName'),
      cellphone: get(data, 'customer.cellphone'),
      items: reject(flattenItems, { isCombo: true }),
      shift: data.shift,
      numItems,
      referrer: data.orderPlatform,
      printType: 'order',
      ordered
    })

    flattenItems.forEach((item, index) => {
      setTimeout(() => {
        var content = document.getElementById('label-' + item.id)
        var pri = document.getElementById('ifmcontentstoprint').contentWindow
        pri.document.open()
        pri.document.write(getPrintTemplate(content.innerHTML))
        pri.document.close()
        pri.focus()
        pri.print()
      }, 500 * index)
    })
  }

  componentDidMount = () => {
    const interval = setInterval(() => {
      if (this.props.currentShift) {
        initSocket(this.props.currentShift.shiftRecordId).then(() => {
          subcribeToNewOrder(this.printOrder)
        })
        clearInterval(interval)
      }
    }, 250)

    // Load trước ở local storage đề phòng trường hợp mạng lỗi
    // không load được data
    const data = store.get('defaultModifierProductMap')
    if (data) {
      this.setState({ defaultModifierProductMap: data })
    }

    workServer.getDefaultModifiers().then(products => {
      const productMap = products.reduce((result, product) => {
        result[product.sku] = {
          defaultModifierSkus: product.defaultModifiers.map(d => d.sku)
        }
        return result
      }, {})

      store.set('defaultModifierProductMap', productMap)

      this.setState({
        defaultModifierProductMap: productMap
      })
    })
  }

  componentWillUnmount = () => {
    unsubcribeToPrint()
    removeSocket()
  }

  render() {
    return (
      <Container>
        <p>
          Bạn nhìn thấy trang này vì đang đang mở trang dùng để in nhãn món. Vui lòng sử dụng trình
          duyệt Firefox Nightly/Firefox Developer Edition phiên bản 95 trở xuống và không cập nhật
          Firefox lên phiên bản cao hơn vì các phiên bản cao hơn sẽ bị lỗi trong quá trình in. Nếu
          máy tính của bạn sử dụng phiên bản cao hơn phiên bản 95 vui lòng liên hệ team HC để được
          cài đặt lại nhé. Vui lòng truy cập{' '}
          <a href="https://ftp.mozilla.org/pub/firefox/nightly/2021/11/2021-11-01-08-46-51-mozilla-central-l10n/firefox-95.0a1.vi.win64.installer.exe">
            {' '}
            Link
          </a>{' '}
          để tải đúng phiên bản Firefox nhé
        </p>
        {this.state.items.map((item, index) => {
          return (
            <div key={item.id}>
              <div id={'label-' + item.id}>
                <div className="printing printing-item">
                  <ItemHeader
                    referrer={this.state.referrer}
                    cellphone={this.state.cellphone}
                    customerName={this.state.customerName}
                    index={index}
                    numItems={this.state.numItems}
                    orderNumber={this.state.orderNumber}
                    ordered={this.state.ordered}
                    saleEntryId={this.state.saleEntryId}
                    shift={this.state.shift}
                  />
                  <div className="item-content">
                    <ItemDescription description={item.description} item={item} />
                    <ItemModifiers
                      item={item}
                      defaultModifierProductMap={this.state.defaultModifierProductMap}
                    />
                    {item.note ? <div>Ghi chú: {item.note}</div> : null}
                  </div>
                  <div className="line-total">
                    <FormattedNumber value={item.soldPrice} />đ
                  </div>
                </div>
              </div>
              <div>=============</div>
            </div>
          )
        })}
        <iframe
          id="ifmcontentstoprint"
          style={{ position: 'relative', height: '3cm', width: '4.5cm' }}
          title="print"
        />
        <div id="test" />
      </Container>
    )
  }
}

import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { Container, Button, Form } from 'semantic-ui-react'
import workServer from '../../../../shared/apis/work-server.api.js'
import Authentication from '../../shared/services/authentication'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import config from '../../../../config/config'
import _ from 'lodash'
class LoginPage extends Component {
  state = {
    username: null,
    password: null,
    date: null,
    shift: null,
    updating: null,
    outlet: null,
    outlets: []
  }

  options = [
    { value: 'S', text: 'Sáng' },
    { value: 'C', text: 'Chiều' },
    { value: 'T', text: 'Tối' }
  ]

  componentDidMount = async () => {
    const { data: allOutlets } = await axios.get(`${config.workServer.uri}/outlets`)
    const filteredOutlets = _.filter(allOutlets, outlet => {
      const outletCode = _.get(outlet, 'code', 'Chưa rõ')
      return /^(S|K)\d+/.test(outletCode) || outletCode === 'B2'
    })
    const mapOutlets = _.map(filteredOutlets, outlet => ({
      value: outlet.code,
      text: `${outlet.code} - ${outlet.name}`
    }))
    this.setState({
      outlets: mapOutlets
    })
  }
  updateField = field => (e, { value }) => {
    this.setState({
      [field]: value
    })
  }

  login = () => {
    this.setState({
      updating: true
    })
    workServer
      .login({
        username: this.state.username,
        password: this.state.password,
        outlet: this.state.outlet
      })
      .then(({ user, currentShift }) => {
        Authentication.setUser(user)
        Authentication.setCurrentShift(currentShift)
        this.props.updateUser(user)
        this.props.updateCurrentShift(currentShift)
        toast.success('Đăng nhập thành công')
        this.props.history.push('/')
      })
      .catch(err => {
        toast.error(err.message)
        this.setState({ updating: false })
      })
  }

  render() {
    return (
      <Container>
        <Form>
          <Form.Input
            label="Tên đăng nhập"
            placeholder="Nhập tên đăng nhập"
            onChange={this.updateField('username')}
          />
          <Form.Input type="password" label="Mật khẩu" onChange={this.updateField('password')} />
          <Form.Select
            label="Cơ sở"
            options={this.state.outlets}
            renderLabel={({ label }) => label}
            onChange={this.updateField('outlet')}
          />
          <Button content="Đăng nhập" onClick={this.login} disabled={this.updating} />
        </Form>
      </Container>
    )
  }
}

export default withRouter(LoginPage)

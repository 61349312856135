import React, { Component } from 'react'
import TopMenu from './TopMenu'
import get from 'lodash/get'

export default class TopMenuContainer extends Component {
  render() {
    const { user, currentShift } = this.props
    let loggedIn = false
    if (get(user, '_id') && get(currentShift, 'shiftRecordId')) {
      loggedIn = true
    }
    return <TopMenu loggedIn={loggedIn} user={user} currentShift={currentShift} />
  }
}

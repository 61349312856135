import store from 'store'
import { initSocket, removeSocket } from '../../../../shared/apis/savor-server.api'

const Authentication = {
  isAuthenticated: false,
  logginIfLogged() {
    const user = store.get('user')
    const currentShift = store.get('currentShift')
    if (user && user._id) {
      this.isAuthenticated = true
    }
    return { user, currentShift }
  },
  getCurrentShift() {
    return store.get('currentShift')
  },
  getUser() {
    return store.get('user')
  },
  setUser(user) {
    store.set('user', user)
    if (user && user._id) {
      this.isAuthenticated = true
    } else {
      this.isAuthenticated = false
    }
  },
  setCurrentShift(shiftRecord) {
    store.set('currentShift', shiftRecord)
    initSocket(shiftRecord.shiftRecordId)
  },
  logout() {
    store.remove('user')
    store.remove('currentShift')
    this.isAuthenticated = false
    removeSocket()
  }
}

export default Authentication

const configObj = {
  workServer: {
    uri: 'https://work.4-handy.com'
    // uri: 'http://192.168.31.161:3000'
  },
  slack: {
    webhookUrl: 'https://hooks.slack.com/services/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'
    // webhookUrl: 'https://hooks.slack.com/services/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'
  }
}

export { configObj }

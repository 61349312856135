exports.getPrintTemplate = content => {
  return `
  <html>
    <head>
      <style>
      @media print {
        @page {
          margin: 0;
        }
        *,
        ::after,
        ::before {
          box-sizing: border-box;
        }
        :root {
          font-size: 10px;
        }

        body {
          padding: 0;
          margin: 0;
          max-width: 4.5cm;
          margin-left: 0.3cm;
          margin-top: 0.1cm;
          color: black !important;
          font-family: Helvetica, sans-serif;
        }

        .printing-item {
          font-family: Helvetica, sans-serif;
          font-weight: 200;
          font-size: 10px;
          max-width: 4.5cm;
          position: relative;
          min-height: 2.6cm;
          /* border: 1px solid black; */
        }

        .printing-item .item-description {
          font-size: 1.1em;
          text-transform: uppercase;
          line-height: 1;
          display: block;
          white-space: pre-line;
          margin-bottom: 0.1em;
        }

        .printing-item .modifiers {
          font-size: 1em;
          line-height: 1;
          border-top: 1px solid black;
          position: relative;
          top: 1;
        }

        .printing-item .header {
          font-size: 1em;
          border-bottom: 1px solid black;
          margin-bottom: 0.3em;
          padding-bottom: 0.2em;
        }
        .printing-item .line-total {
          font-size: 1em;
          line-height: 1;
          padding: 0.15em 1em;
          text-align: right;
          border-top: 1px solid black;
          position: absolute;
          width: 100%;
          bottom: 0;
        }
      }
      </style>
    </head>
    <body>
      ${content}
    </body>
  </html>
  `
}

import React from 'react'
import ReactDOM from 'react-dom'
import './css/semantic.min.css'
import './index.css'
import 'react-toastify/dist/ReactToastify.css'
import App from './app/screens/App/App'
import { unregister } from './registerServiceWorker'
import { IntlProvider } from 'react-intl'

ReactDOM.render(
  <IntlProvider locale="en">
    <App />
  </IntlProvider>,
  document.getElementById('root')
)
unregister()

import React, { Component } from 'react'
import { ToastContainer } from 'react-toastify'

import { Switch, Route, Redirect, BrowserRouter as Router } from 'react-router-dom'

import AuthenticationContainer from './components/AuthenticationContainer'
import HomePage from './screens/HomePage/HomePage'
import TopMenuContainer from './components/TopMenu/TopMenuContainer'
import LoginPage from './screens/LoginPage/LoginPage'

import './App.css'
import PrivateRoute from './components/PrivateRoute'
import NotFound from './screens/NotFound'
import PrintOrder from './screens/PrintOrder/PrintOrder'

class App extends Component {
  state = {
    isAuthenticated: false,
    currentShift: null,
    user: null
  }

  updateCurrentShift = currentShift => {
    this.setState({ currentShift })
  }

  updateUser = user => {
    this.setState({ user, isAuthenticated: true })
  }

  clearCurrentShift = () => {
    this.setState({ currentShift: null })
  }

  clearUser = () => {
    this.setState({ user: null, isAuthenticated: false })
  }

  render() {
    return (
      <Router>
        <AuthenticationContainer
          updateUser={this.updateUser}
          updateCurrentShift={this.updateCurrentShift}
        >
          <TopMenuContainer currentShift={this.state.currentShift} user={this.state.user} />
          <ToastContainer hideProgressBar />

          <Switch>
            <Route path="/home" component={HomePage} exact></Route>
            {this.state.currentShift ? (
              <Redirect from="/" to="/print-order" exact />
            ) : (
              <Redirect from="/" to="/login" exact />
            )}

            {/* <PrivateRoute path="/print-label" component={PrintLabel} /> */}
            <PrivateRoute
              path="/print-order"
              component={PrintOrder}
              currentShift={this.state.currentShift}
            />

            {this.state.currentShift ? (
              <Redirect from="/login" to="/print-order" exact />
            ) : (
              <Route
                path="/login"
                render={() => (
                  <LoginPage
                    updateUser={this.updateUser}
                    updateCurrentShift={this.updateCurrentShift}
                  />
                )}
              />
            )}
            <Route path="/" component={NotFound} />
          </Switch>
        </AuthenticationContainer>
      </Router>
    )
  }
}

export default App

import React, { Component } from 'react'
import Authentication from '../shared/services/authentication'
import get from 'lodash/get'

export default class AuthenticationContainer extends Component {
  componentWillMount = () => {
    const { user, currentShift } = Authentication.logginIfLogged()
    if (get(user, '_id') && get(currentShift, '_id')) {
      this.props.updateUser(user)
      this.props.updateCurrentShift(currentShift)
    }
  }

  render() {
    return <div children={this.props.children} />
  }
}

import axios from 'axios'
import moment from 'moment'
import config from '../../config/config'
import get from 'lodash/get'
import Authentication from '../../screens/App/shared/services/authentication'

const getErrorMessage = error => {
  const serverMessage = get(error, 'response.data.message')
  if (serverMessage) {
    throw new Error(serverMessage)
  } else {
    throw error
  }
}

const workServer = {
  login: ({ username, password, shift, date = moment().format('YYYY-MM-DD'), outlet = '' }) => {
    return workServer.post(`login`, { username, password, shift, date, outlet })
  },
  getCustomer: queryString => {
    return axios.get(`${config.workServer.uri}/api/customers/search`, {
      params: {
        searchText: queryString
      }
    })
  },
  readCustomer: customerId => {
    return workServer.get(`customers/${customerId}`)
  },
  updateCustomer: customer => {
    return workServer.put(`customers/${customer._id}`, customer)
  },
  addCustomer: customer => {
    return axios
      .post(`${config.workServer.uri}/api/customers`, customer)
      .catch(getErrorMessage)
      .catch(e => {
        if (e.message.match(/bị trùng/)) {
          throw new Error(
            'Mã thẻ hoặc số điện thoại bị trùng với dữ liệu trong hệ thống. Kiểm tra lại mã thẻ hoặc số điện thoại.'
          )
        }
        throw e
      })
      .then(response => response.data)
  },
  add70kVoucher: ({ customer = {}, comboCode = '' }) => {
    return workServer.post(`customers/${customer._id}/combo-70k`, {
      comboCode
    })
  },
  getVouchers: customer => {
    return workServer.get(`customers/${customer.customerId}/value-vouchers`)
  },
  getProducts: params => {
    const currentShift = Authentication.getCurrentShift()
    return workServer.get('products', {
      currentShiftId: currentShift ? currentShift._id : null,
      ...params
    })
  },
  convertProduct: ({ product, user }) => {
    const { _id: currentShiftId } = Authentication.getCurrentShift()
    return workServer.post('convert-product', {
      product,
      user,
      currentShiftId
    })
  },
  foodPrep: ({ inQuantity = 0, outQuantity = 0, user }) => {
    const { _id: currentShiftId } = Authentication.getCurrentShift()
    return workServer.post('food-prep', {
      inQuantity,
      outQuantity,
      user,
      currentShiftId
    })
  },
  getSellingProducts: () => {
    const currentShift = Authentication.getCurrentShift()
    return workServer.get('selling-products', { currentShiftId: currentShift._id })
  },
  updateProductStatus: (productId, status) => {
    const { _id: currentShiftId } = Authentication.getCurrentShift()
    return workServer.post(`products/${productId}/update-status`, {
      inStock: status,
      currentShiftId
    })
  },
  getDefaultModifiers: () => {
    const currentShift = Authentication.getCurrentShift()
    return workServer.get('default-modifiers', { currentShiftId: currentShift._id })
  },
  get: (url, params) => {
    return axios
      .get(`${config.workServer.uri}/api_savor/${url}`, { params })
      .then(response => response.data)
      .catch(getErrorMessage)
  },
  post: (url, data) => {
    return axios
      .post(`${config.workServer.uri}/api_savor/${url}`, data)
      .then(response => response.data)
      .catch(getErrorMessage)
  },
  put: (url, data) => {
    return axios
      .put(`${config.workServer.uri}/api_savor/${url}`, data)
      .then(response => response.data)
      .catch(getErrorMessage)
  },
  addSaleEntry: ({
    items = [],
    saleTotal = 0,
    discount = 0,
    customer = {},
    salePrograms = [],
    referrer = '',
    coupon = '',
    user = {},
    currentShift = {},
    saleEntryType = '',
    shippingAddress = {},
    voucher = null,
    clientUid = null,
    referrerMethod = null,
    surveyAnswer = null,
    ...rest
  }) => {
    return workServer.post('sale-entries', {
      items,
      saleTotal,
      discount,
      customer,
      salePrograms,
      referrer,
      coupon,
      user,
      currentShift,
      saleEntryType,
      shippingAddress,
      voucher,
      clientUid,
      referrerMethod,
      surveyAnswer,
      ...rest
    })
  },
  addSurvey: ({
    surveyId = '',
    surveyGroup = '',
    answers = [],
    saleEntry = '',
    outlet = '',
    customer = '',
    currentShift = {},
    user = {}
  }) => {
    return workServer.post('surveys', {
      surveyId,
      surveyGroup,
      answers,
      saleEntry,
      outlet,
      customer,
      currentShift,
      user
    })
  },
  getPendingSaleEntries() {
    const currentShift = Authentication.getCurrentShift()
    return this.get('sale-entries/pending', { currentShiftId: currentShift._id })
  },
  markDoneSaleEntry(saleEntryId) {
    const currentShift = Authentication.getCurrentShift()
    return this.post(`sale-entries/${saleEntryId}/done`, { currentShiftId: currentShift._id })
  }
}

export default workServer

import openSocket from 'socket.io-client'
import { getOutlets } from '../models/outlets.model'
import { getCodeFromShiftRecordId } from '../utils/shift-records.utils'
import {baseUrl} from '../../config/environments'

let labelSocket = null

function subscribeToPrint(cb) {
  if (labelSocket) {
    labelSocket.on('print', data => cb(null, data))
  }
}

function unsubcribeToPrint() {
  labelSocket.removeAllListeners()
}

function subcribeToNewOrder(cb) {
  if (labelSocket) {
    console.log({ labelSocket })
    labelSocket.on('newOrder', data => cb(null, data))
  }
}

async function initSocket(shiftRecordId) {
  console.log(shiftRecordId)
  if (!shiftRecordId) return
  removeSocket()
  const outletCode = getCodeFromShiftRecordId(shiftRecordId)
  const matchOutlet = (await getOutlets()).find(outlet => outlet.code === outletCode)
  console.log(`${baseUrl}${matchOutlet.labelPrinterNamespaceString}`)
  labelSocket = openSocket(`${baseUrl}${matchOutlet.labelPrinterNamespaceString}`)
}

function removeSocket() {
  if (labelSocket) {
    console.log('disconect label socket')
    labelSocket.disconnect()
  }
}

export {
  subscribeToPrint,
  unsubcribeToPrint,
  initSocket,
  removeSocket,
  subcribeToNewOrder,
  labelSocket
}
